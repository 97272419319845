<template>
    <div class="language-selection">
        <form @submit.prevent>
            <label for="language">
                <app-icon
                    name="globe"
                    aria-label="Update language"
                />
            </label>

            <b-form-select
                id="language"
                name="language"
                :options="localeOptions"
                v-model="currentLocale"
                size="sm"
            />
        </form>
    </div>
</template>

<script>
    import { BFormSelect } from 'bootstrap-vue';
    import { mapState } from 'vuex';

    export default {
        name: 'language-selection',

        components: { BFormSelect },

        computed: {
            ...mapState('locales', [
                'locale',
                'locales',
            ]),

            /**
             * Return the options for the select.
             *
             * @returns {{text: string, value: string}[]}
             */
            localeOptions() {
                return this.locales.map((locale) => ({
                    text: locale,
                    value: locale,
                }));
            },

            currentLocale: {
                get() {
                    return this.locale;
                },

                set(locale) {
                    let path = this.$route.path;

                    const { 1: existingLocale } = /\/([a-z]{2}-[A-Z]{2})\/.*/.exec(path) || [];

                    if (existingLocale) {
                        if (locale === 'en') {
                            path = path.replace(`/${ existingLocale }`, '');
                        } else {
                            path = path.replace(existingLocale, locale);
                        }
                    } else {
                        path = `/${ locale }${ path }`;
                    }

                    this.$router.push(path);
                },
            },
        },
    };
</script>

<style lang="scss" scoped>
    ::v-deep {
        @import "~bootstrap/scss/custom-forms";
    }

    form {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    label {
        margin-bottom: 0;
        color: $itsu-primary;
    }

    .custom-select-sm {
        width: auto;
    }
</style>
