export default {
    /**
     * Fetch all recipes from the API.
     *
     * @param {object} context
     * @param {object} payload
     * @param {boolean} payload.includeCategories
     * @param {boolean} payload.selectedCategory
     *
     * @returns {Promise<object>}
     */
    async fetchRecipes(context, { includeCategories = true, selectedCategory } = {}) {
        const filters = {
            page: {
                id: {
                    notNull: true,
                },
            },
        };

        if (selectedCategory) {
            filters.recipe_category = {
                slug: {
                    eq: selectedCategory,
                },
            };
        }

        const { default: query } = await import('../../queries/recipes.graphql');

        return this.$strapi.query(query, {
            includeCategories,
            filters,
        });
    },
};
